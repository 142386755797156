exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */)
}

